import {ILocale} from "./type"

const ch: ILocale = {
	header: {
		blog: "博客",
		enterWokOS: "进入WOLF-OS",
		enterNFT: "NFT Shop",		
		features: "特征",
		franshise: "[医]法兰西丝",
		vision: "游戏",
		nft: "NFT",
		staking: "Staking",
		roadmap: "路线图",
		token: "令牌",
		bookOfKnowlage: "书与音乐",
	},

	 playSection: {
		openWorld: "AI 游戏持久非线性开放世界元宇宙游戏金融",
		sound: "声音: ",
		on: "开",
		off: "关"
	  },
	  subscribeSection: {
		dontMiss: "不要错过机会",
		weAreGoing: "我们即将上线",
		beTheFirst: "成为第一批了解的人，不要错失良机",
		subscribe: "订阅",
		success: "您的信息已提交！岛上见！",
		networkError: "网络错误",
		emailError: "邮箱格式错误",
		placeholder: "邮箱地址",
		joinToFreeNft: "今天订阅并",
		groupName: "加入我们的 Discord",
		claimYourNft: "来领取您的",
		freeNft: "免费",
		claimNft: "NFT 狼奖励。逃离现实，加入部落",
		title: "AI 游戏",
		definition1: "游戏 AI 是一种革命性的新方式，通过 AI 来创造非线性游戏玩法。这种新方法将使元宇宙和持久虚拟世界能够无限制地娱乐参与者。",
		definition2: "传统游戏，即使是开放世界 RPG，都是线性的，需要玩家按照特定顺序完成某些任务才能通关。而游戏 AI 方法会根据玩家的能力调整难度、事件和游戏体验，同时考量他们的社交地位、排名、武器和能力。",
		definition3: "这创造了一个可以以任何顺序自由探索的永无止境的游戏。这种全新的游戏方式将允许元宇宙在持久世界中提供高质量的游戏化体验。"
	  },
	  aigamingSection: {
			title:"AI Gaming",
			definition1: "Gaming AI is a revolutionary way to use AI to create non-linear gameplay. This new approach will enable metaverses and persistent virtual worlds to entertain their participants without limitations.", definition2: "While normal games, even open-world RPGs, are linear and require players to complete certain tasks in a specific order to reach the end, the Gaming AI approach senses the player\'s abilities and adapts the difficulty, events, and gaming experience to their social status, rank, weapons, and abilities. ",
			definition3: "This creates a never-ending game that can be played in any order with total freedom of movement. This new approach to gaming will allow metaverses to offer high-quality gamification in a persistent world."
		},	

  sliderSection: {
    "firstSlideTitle": "保护岛屿，\n和你的朋友们\n免受变异体侵害",
    "firstSlideText": "去检查每个人是否安好，电力是否还在运行，对抗邪恶势力的防护是否仍然有效。",
    "firstStory": "变异体",
    "secondStory": "开放世界",
    "thirdStory": "玩游戏赚取收益"
  },
  mapSection: {
    "title": "探索多个地点",
    "firstAdvantageTitle": "8 000 000 平方米",
    "firstAdvantageText": "岛屿面积",
    "secondAdvantageTitle": "1 000 人",
    "secondAdvantageText": "可同时在岛上游戏的人数",
    "thirdAdvantageTitle": "> 100 000",
    "thirdAdvantageText": "树木、岩石、人物和许多可以参观的地方"
  },
  charactersSection: {
    "title": "使用 NFT 自定义角色",
    "text": "Metaisland 是一个社交网络平台，拥有虚拟头像、聊天、好友关系、状态和点赞功能。您可以从超过 150 个独特的 3D NFT 中选择您的角色。"
  },
  learnTheStorySection: {
    "title": "了解故事",
    "firstStory": "历史",
    "secondStory": "租用商店",
    "thirdStory": "对抗变异体",
    "fourthStory": "探索海洋"
  },
  nftSection: {
    "title": "独特的 2D NFT",
    "button": "前往 NFT 商店",
    "aquamen": "水人",
    "military": "军事",
    "punks": "朋克",
    "redcards": "红牌",
    "tribes": "部落",
    "builders": "建造者",
    "wolfWood": "木狼",
    "wolfIron": "铁狼",
    "daemons": "恶魔"
  },
  playToEarnSection: {
    "title": "边玩边赚",
    "subtitle": "Metaisland 是一个社交网络平台，拥有虚拟头像、聊天、好友关系、状态和点赞功能。您可以选择角色的皮肤，并通过 RPG 特性打造自己的个性。",
    "earn": "赚取",
    "earnFirstTitle": "发现加密货币",
    "earnFirstText": "空投和采矿石",
    "earnSecondTitle": "完成任务",
    "earnSecondText": "从 NPC 接取任务",
    "earnThirdTitle": "租用商业场所",
    "earnThirdText": "可租用餐厅或货币兑换场所",
    "spend": "花费",
    "spendFirstTitle": "NFT",
    "spendFirstText": "音频、艺术、皮肤、物品和技能",
    "spendSecondTitle": "购买食物",
    "spendSecondText": "您需要管理资源",
    "spendThirdTitle": "喝酒",
    "spendThirdText": "啤酒也可以帮助您补充能量"
  },
  soonSection: {
    "title": "敬请期待",
    "subtitle": "原创元宇宙品牌",
    "soonFirstTitle": "先进的游戏引擎",
    "soonFirstText": "Meta Island 使用虚幻引擎 5 构建一个实时 3D 交互的有趣合作开放世界。",
    "soonSecondTitle": "最大可组合性",
    "soonSecondText": "赚取 DeFi，玩游戏，与他人聊天，参与 DAO，并通过 Holochain 在 Genesis Meta Island 和其用户中体验 Web3 经济。",
    "soonThirdTitle": "Genesis 岛屿和特许经营",
    "soonThirdText": "通过顶尖金融科技技术拓展您的 Web3 视野。使用 Genesis 岛屿的现成机会，或者通过特许经营创建自己的版本！"
  },
  roadmapSection: {
    "title": "路线图",
    "first": "在我们的市场上销售 NFT",
    "second": "首个岛屿的可玩演示",
    "third": "对话系统、冒险、讲故事",
    "fourth": "战斗系统，垂直开发",
    "fifth": "特许经营和水平开发，业务发展",
    "button": "阅读更多关于路线图的信息"
  }	,
  blogSection: {
		title: "Blog",
		button: "Read more posts",
	},
	
	teamSection: {
		title: "Metaisland Inc. Studio",
		subtitle: "Metaisland Inc.工作室由遍布全球的20多位才华横溢的人才组成",
		nicolas: {
			"name": "Nicolas Choukroun",
			"profession": "首席执行官（CEO）",
			"about": "曾任职于Ubisoft、Mindscape、Fox、Loriciel、Cryo、EA、Euronext"
		},
		laurent: {
			name: "Laurent Cluzel",
			profession: "图形总监",
			about: "曾任职于Ubisoft、Sony、Psygnosis、Wizama、EA、Namco"
		},
		ethan: {
			name: "Ethan Lewis Maltby",
			profession: "音效与音乐总监",
			about: "曾合作于UEFA、FA、NFL、RBS、Microsoft、Nestle、Cineworld、Telenet、BP、Wella、Walls、Leeds Castle、KCC"
		},
		iliah: {
			name: "Ilya Cerberex",
			profession: "区块链首席专家",
			about: "精通Python、PHP、C++、全栈开发、NEO链、Solidity、主机托管"
		},
		pascale: {
			name: "Pascale Piel",
			profession: "首席财务官（CFO）",
			about: "曾任职于Antabio SAS、Theravectys、Sofrepost、Novexel、Axa"
		},
		pierre: {
			name: "Pierre Esteve",
			profession: "故事编写音乐人",
			about: "曾合作于Arte、电影、电视、Starsync"
		},
		vitalik: {
			name: "Vitalik Mraksoll",
			profession: "区块链开发者",
			about: "精通C++、React、Python、Solidity、Rust"
		},
		sanny: {
			name: "Sanni Muhammed",
			profession: "社区经理负责人",
			about: "精通Twitter、Reddit、Discord"
		},
		daniel: {
			name: "Daniel Morar",
			profession: "Unreal引擎首席开发者",
			about: "专长Blueprint和ALS角色系统"
		},
		kriss: {
			name: "Krzysztof Sandomierski",
			profession: "项目经理与测试员",
			about: "石油行业20年以上团队管理经验"
		},
		"abhay": {
			name: "Abhay Prakashchandra Mahajan",
			profession: "后端/WOK-OS API开发者",
			about: "PHP/Mysql高手"
		},
		stephane: {
			name: "Stephane Picq",
			profession: "游戏音乐作曲家",
			about: "代表作品包括Atlantis、Dune、Megarace、Captain Blood"
		},
		beesirin: {
			name: "Beesirin Impaw",
			profession: "泰国总经理",
			about: ""
		},
		sandomierski: {
			name: "Sandomierski Krzysztof",
			profession: "项目经理",
			about: ""
		}
	},
	advisorySection: {
		title: "顾问团队",
		hassan: {
			"name": "Hassan (Hatu) Sheikh",
			"profession": "代币经济与筹资顾问",
			"about": "Daomaker联合创始人，ApeTerminal.io创始人"
		},
		iftikhar: {
			"name": "Iftikhar Qasim",
			"profession": "SL2 Capital与Hillstone Finance高级顾问",
			"about": ""
		},
		"serge": {
			name: "Serge Hascoet",
			profession: "Ubisoft首席创意官，任职超过30年",
			about: ""
		},
		"david": {
			name: "David Atkinson",
			profession: "代币经济顾问",
			about: "Holochain联合创始人"
		},
		"andrey": {
			name: "Audrey He",
			profession: "市场与筹资顾问",
			about: "NEO链"
		},
		"babar": {
			name: "Babar Shabir",
			profession: "NFT与区块链战略顾问",
			about: ""
		},
		"konstantin": {
			name: "Konstantin Pechesky",
			profession: "Trinity总裁，投资与加密货币专家",
			about: "Trinity - 风险投资俱乐部"
		}
	},
	partnersSection: {
		title: "合作伙伴",
		subtitle: "团队曾发布过游戏或与以下公司合作",
		investors: "种子投资者",
		technologyAndLegalPartners: "技术与法律合作伙伴",
		wizama: "自2017年以来的魔法棋盘游戏开发商",
		nintendo: "日本跨国电子游戏公司",
		sega: "日本跨国电子游戏和娱乐公司",
		ea: "美国电子游戏公司",
		sony: "日本跨国集团公司",
		mindscape: "法国电子游戏开发商",
		cryo: "法国电子游戏开发商",
		ubisoft: "法国电子游戏公司"
	},
	popUpMining: {
		title: "挖矿与空投区域",
		text: "每两小时，警报响起并启动空投。4分钟内未被玩家获取的加密货币将自动销毁。",
		more: "更多"
	},
	popUpFactory: {
		title: "电力工厂",
		text: "游戏中，整个电力系统完全模拟。电缆覆盖所有区域，还有一些太阳能面板。特斯拉线圈将能量发送到电力工厂进行管理、存储并传送至岛屿其他部分。玩家可以控制该区域的电力开关。",
		more: "更多"
	},
	popUpCyber: {
		title: "赛博区",
		text: "赛博区是岛上最大的建筑之一，可容纳约500人。这里是一个充满酒精、毒品和色情的狂野之地。",
		more: "更多"
	},
	popUpSlum: {
		title: "贫民区",
		text: "这是游戏初期获取房间的最便宜选择。虽然远离挖矿/空投区域，但氛围很棒，非常适合新手玩家。",
		more: "更多"
	},
	popUpTech: {
		title: "科技城",
		text: "科技城是行政区域，包括会议室和传送站。这里聚集了岛上的知识分子，可获取关于岛屿的信息。区域内设有博物馆、房屋、餐厅、美食广场和超市。",
		more: "更多"
	},
	popUpLeaw: {
		title: "Leaw海滩",
		text: "Leaw海滩是首个可探索的海滩。后续更新将添加更多海滩。这是一个放松的好地方，夜总会和酒吧应有尽有。",
		more: "更多"
	},
	popUpTesla: {
		title: "特斯拉线圈区域",
		text: "这些区域为岛上的建筑物生成能量，能量来自地下的加密货币矿。部分特斯拉区域位于危险的荒野区，有自动防御功能，靠近的玩家会被摧毁。特斯拉能量区靠近科技城，玩家需确保其持续运行以防止基础设施断电，从而避免野生区生物入侵保护区。",
		more: "更多"
	},
	popUpTribe: {
		title: "部落村庄",
		text: "这是游戏初期获取房间的最便宜选择。虽然远离挖矿/空投区域，但氛围很棒，非常适合新手玩家。",
		more: "更多"
	},
	footer: {
		whitepaper: "白皮书",
		pitchdeck: "详细投资计划书",
		pitchdecklite: "简版投资计划书",
		metaisland: "METAISLAND"
	},
	islToken: {
		title: "ISL代币",
		subtitle: "ISL代币是Metaisland生态系统的主要以太坊代币：层-1、元宇宙、NFT生态系统以及原生GameFi。代币经济包括质押、锁定、流动性激励、回购和销毁。这些功能由游戏特权和元宇宙经济需求推动。",
		tokenEconomy: "代币经济",
		playIncome: "游戏收入",
		playIncomeFirstTitle: "月订阅",
		playIncomeFirstText: "元宇宙免费游玩，但要解锁所有功能，参与者需支付至少10美元的月费。",
		playIncomeSecondTitle: "周边商品",
		playIncomeSecondText: "Metaisland是一个拥有角色和故事的特许经营品牌，可像其他流行品牌一样开发各种周边商品。",
		playIncomeThirdTitle: "质押玩法",
		playIncomeThirdText: "玩家可租用商店以获得MPR（月度收益百分比）。质押收益取决于商店的位置和成功程度。",
		nftIncome: "NFT收入",
		nftIncomeFirstTitle: "NFT市场",
		nftIncomeFirstText: "在应用内NFT市场进行的所有NFT交易均收取2.5%的费用",
		nftIncomeSecondTitle: "NFT销售",
		nftIncomeSecondText: "我们的NFT解锁了元宇宙中的功能，玩家需要收集许多NFT才能访问所有这些功能。",
		nftIncomeThirdTitle: "NFT租赁",
		nftIncomeThirdText: "玩家可用赚取的代币租用我们的NFT，同时也可以租出自己拥有的NFT以赚取额外收入。"
	},
	tokenEconomy: {
		firstTitle: "游戏经济中的ISL代币价值",
		gameEconomyFirstTitle: "回购",
		gameEconomyFirstText: "50%的游戏收入用于在市场上回购代币",
		gameEconomySecondTitle: "销毁",
		gameEconomySecondText: "治理机构可投票销毁最多50%的回购代币",
		gameEconomyThirdTitle: "质押奖励",
		gameEconomyThirdText: "最多50%的市场回购代币将分发给质押者",
		secondTitle: "生态系统中的ISL代币价值",
		ecosystemFistTitle: "质押",
		ecosystemFistText: "质押代币和流动性代币可解锁某些WOK和化身皮肤。化身在排行榜、好友和公共地图上可见。",
		ecosystemSecondTitle: "折扣",
		ecosystemSecondText: "游戏NFT交易收取2.5%的版税，若玩家质押了最低数量的游戏代币，该费用可降至2%。",
		ecosystemThirdTitle: "回购",
		ecosystemThirdText: "50%的NFT交易费用用于市场回购，DAO可以投票销毁这些代币",
		ecosystemFourthTitle: "质押收益",
		ecosystemFourthText: "最多50%的NFT交易费用将作为质押奖励发放"
	},
  "tokenDistribution": {
    "title": "代币分配",
    "secondTitle": "总计：100% — 1,000,000,000"
  },
  "visionMetaisland": {
    "title": "什么是Metaisland？",
    "subtitle": "为数十亿用户打造的元宇宙基础设施",
    "text": "Metaisland是专为元宇宙构建的区块链基础设施。兼容大多数主流钱包，与所有主要区块链连接，同时结合了去中心化金融（DeFi）、NFT和边玩边赚（Play to Earn）的独特革命性概念。"
  },
  "metaverseBlock": {
    "firstTitle": "到2030年，元宇宙经济价值13万亿美元",
    "secondTitle": "一个属于每个人的元宇宙",
    "subtitle": "华尔街公司摩根士丹利认为元宇宙是一个价值8万亿美元的潜在市场，很可能成为“下一代社交媒体、流媒体和游戏平台”。\n" +
      "City更加乐观，并表示：“我们认为元宇宙是下一代互联网——在持续和沉浸式的方式中结合物理和数字世界——而不仅仅是一个虚拟现实世界，”报告称。“一个设备无关的元宇宙，可通过电脑、游戏机和智能手机访问，可能形成一个非常大的生态系统。”",
    "date": "（《财富》2022年4月）",
    "FirstTitle": "Metaisland ISL实用代币",
    "FirstText": "ISL代币是驱动Metaisland的燃料。岛屿所有者需要在市场上购买ISL代币来补充奖励池。",
    "SecondTitle": "2D NFT 多链",
    "SecondText": "提供9个系列的2D PFP NFT，所有都具有用途。它们将在多链和多个不同市场上可用。",
    "ThirdTitle": "具有RPG功能的3D NFT",
    "ThirdText": "我们的3D NFT可以直接在游戏中使用，并具有角色扮演游戏（RPG）功能，例如力量、速度、生命值、法力值、饥饿值、口渴值等。",
    "FourthTitle": "NFT商店，购买或租赁",
    "FourthText": "我们的NFT可以通过现金、加密货币购买，甚至可以租赁！无需富有也能享受Metaisland。",
    "FifthTitle": "WOK-OS管理您的身份",
    "FifthText": "WOK-OS是我们的后台系统，用于管理用户身份，确保每个元宇宙参与者的隐私。",
    "SixthTitle": "Metaisland特许经营",
    "SixthText": "表现最好的元宇宙用户将有机会管理自己的岛屿，通过经营自己的Metaisland谋生。",
    "firstAdvantageTitle": "现金订阅",
    "firstAdvantageText": "一个拥有1000名玩家的岛屿每月可以产生20,000.00美元的销售额。",
    "secondAdvantageTitle": "加密货币订阅",
    "secondAdvantageText": "100万玩家每月将产生数百万USDT的销售额。",
    "thirdAdvantageTitle": "NFT销售",
    "thirdAdvantageText": "虽然NFT不是必需的，但其独特属性使其非常吸引人。",
    "fourthAdvantageTitle": "NFT版税",
    "fourthAdvantageText": "每次转售收取10%版税，长期来看可产生巨大的收入流。",
    "fifthAdvantageTitle": "特许经营",
    "fifthAdvantageText": "特许经营使我们成为内容提供商，并销售API密钥，无需在每个国家进行本地化。",
    "sixthAdvantageTitle": "商品销售",
    "sixthAdvantageText": "在线商店中出售T恤、杯子、纪念品和其他与游戏相关的物品。"
  },
  "visionSlider": {
    "title": "Metaisland为每个人提供一个元宇宙",
    "subtitle": "Metaisland是一个社交网络平台，提供虚拟化身、聊天、好友关系、状态更新和点赞。您可以从超过150种独特的3D角色中选择一个，并通过RPG风格的特性打造自己的个性。"
  },
  "booksPlay": {
    "title": "Metaisland",
    "text": "拥有独特的系列书籍"
  },
  "books": {
		titleMusic: "Choose your Music",
		firstTitleMusic: "Metaisland Album 1",
		firstDescriptionMusic: "Orchestral/Cinematic Music",
		firstTextMusic: "By Ethan Lewis Maltby",		
		secondTitleMusic: "Metaisland Album 2",
		secondDescriptionMusic: "Ambient/Melodic/Techno Music",
		secondTextMusic: "By Stephane Picq",				
		
		titleBooks: "Choose your book",

	  
    "title": "选择您的书籍",
    "firstTitle": "红牌",
    "firstDescription": "The red Cards",
    "firstText": "作者：Nicolas Choukroun",
    "secondTitle": "部落",
    "secondDescription": "The Tribes",
    "secondText": "作者：Nicolas Choukroun",
    "thirdTitle": "水行者",
    "thirdDescription": "The Punks",
    "thirdText": "作者：Nicolas Choukroun",
    "fourthTitle": "军队",
    "fourthDescription": "描述",
    "fourthText": "作者：Nicolas Choukroun, Nick Brown",
    "fifthTitle": "朋克",
    "fifthDescription": "描述",
    "fifthText": "作者：Nicolas Choukroun, Nick Brown",
    "sixthTitle": "恶魔",
    "sixthDescription": "描述",
    "sixthText": "作者：Nicolas Choukroun, Nick Brown",
    "seventhTitle": "建设者",
    "seventhDescription": "描述",
    "seventhText": "作者：Nicolas Choukroun, Nick Brown",
    "eightsTitle": "狼木",
    "eighthDescription": "描述",
    "eightsText": "作者：Nicolas Choukroun, Nick Brown",
    "ninthTitle": "狼铁",
    "ninthDescription": "描述",
    "ninthText": "作者：Nicolas Choukroun, Nick Brown"
  },
  "stakingSlider": {
    "title": "质押实际上是租一个商店",
    "subtitle": "您可以拥有或租用我们的3D NFT。每个系列都有其特定的RPG风格特性。它们可以在游戏中使用。",
    "secondTitle": "点击租用您自己的商店",
    "button": "前往租赁"
  },
  "stakingMechanism": {
    "title": "Metaisland",
    "subtitle": "拥有独特的质押机制",
    "button": "前往质押",
    "firstStep": "在Metaisland租一个商店",
    "secondStep": "每个商店都有其MPR（每月百分比收益率）",
    "thirdStep": "每月获得租金价格% MPR的收入"
  },
  "nftMechanism": {
    "title": "Metaisland",
    "subtitle": "拥有独特的NFT功能",
    "button": "前往NFT商店",
    "firstStep": "前往NFT商店",
    "secondStep": "您可以拥有或租用NFT，使用现金或钱包支付",
    "thirdStep": "现在您可以在元宇宙中使用您的NFT"
  },
  "pageNotFound": {
    "title": "404 - 页面未找到",
    "subtitle": "您是否遗漏了什么？",
    "button": "返回主页"
  },
  "sinceTimer": {
    "title": "我们正在建设Metaisland已有",
    "subtitle": "关注我们并加入我们的社区",
    "second": "秒",
    "minute": "分钟",
    "hour": "小时",
    "day": "天",
    "month": "月",
    "year": "年",
    "seconds": "秒",
    "minutes": "分钟",
    "hours": "小时",
    "days": "天",
    "months": "月",
    "years": "年"
  },
  "subscribeEmail": {
    "title": "幸存者，您做到了！",
    "alreadySub": "已经加入！",
    "subtitle": "欢迎来到Metaisland！\n您已经迈出了逃离残酷现实和无聊生活的第一步！\n在Metaisland，我们正在创造一个未来的虚拟现实，其中包括经济体系、社交系统以及各种娱乐方式，且没有毒性的环境。一个尊重言论自由的去中心化地方，您可以与朋友见面，创造一个不同的身份，做非凡的事情，生活在一个充满神秘的有趣岛屿上。\n您可以享受一杯啤酒放松，与朋友交谈，或者前往野外区域追击变异体，体验动作、战斗和爆炸。您还可以通过游戏中的各种DEFI可能性来将游戏时间货币化，这是其他娱乐产品所无法提供的。\n元宇宙是未来！无需等待很长时间，今天就可以加入这场革命。\n作为您加入我们建设Metaisland旅程的感谢，我们很高兴为您提供赢取免费NFT的机会。\n这个NFT是创始人NFT，拥有独特的功能，可以作为VIP会员进入任何Metaisland而无需申请公民身份。不要错过这个机会！",
    "toRedeem": "要兑换此免费NFT，请按照以下步骤操作。",
    "discord": "Discord",
    "joinDiscord": "加入我们的Discord服务器：",
    "crew": "Crew3",
    "joinCrew": "前往我们的Crew 3页面并完成任务：",
    "bottomText": "查看我们每周的播客系列Meta Talks，了解关于元宇宙的最新信息。Metaisland的创始人兼CEO Nikko为您带来全面解读，务必加入以了解更多关于加密货币、GameFi和元宇宙的信息。\n让我们一起建设Metaisland！",
    "talkWith": "Meta Talk With Nikko CEO MetaIsland - 免费赢取NFT - YouTube",
    "button": "返回主页"
  },
  "box": {
    "title": "点击生成NFT魔法盒！",
    "button": "生成",
    "nftTitle": "为早期用户提供的独家优惠",
    "nftSubtitle": "您将以一个NFT的价格获得3个！价格仅为0.1 ETH",
    "firstNftTitle": "恶魔#41",
    "firstNftText": "NFT 2D化身",
    "secondNftTitle": "查理#08",
    "secondNftText": "NFT 2D化身",
    "thirdNftTitle": "狼木#175",
    "thirdNftText": "NFT 2D化身"
  },
  "browseNft": {
    "title": "METAISLAND 3D NFT",
    "subtitle": "您可以拥有或租用3D NFT。每个系列都有其特定的RPG风格特性。它们可以在游戏中使用，其外观如下",
    "secondTitle": "点击浏览NFT",
    "button": "浏览",
    "firstNftTitle": "约翰#04",
    "firstNftText": "NFT 3D化身",
    "secondNftTitle": "阿莉安娜#05",
    "secondNftText": "NFT 3D化身",
    "thirdNftTitle": "刺客#01",
    "thirdNftText": "NFT 3D化身"
  },
  "mysterySlider": {
    "title": "METAISLAND 2D NFT",
    "subtitle": "我们的2D NFT都有特定的功能，例如赋予游泳能力或免疫毒素。我们有7个阵营，每个阵营都有自己的历史和游戏内功能。玩家可以拥有一个NFT组合，在元宇宙中为其3D角色添加更多能力。",
    "secondTitle": "点击了解更多关于阵营的信息",
    "button": "知识之书",
    "secondButton": "浏览NFT狼通行证",
    "wolfTitle": "METAISLAND狼通行证",
    "wolfText": "我们的狼通行证用于进入岛上的秘密隐藏地点。它们还可作为我们的DAO投票权并获得私人测试版的访问权限。此外，它们外观酷炫创意十足，适合作为您最喜欢的社交网络的个人资料图片。我们的所有NFT都有2个版本，一个是无文字的纯净版，一个是增强文字版。",
    "nftTitle": "狼通行证",
    "nftText": "Metaisland"
  }
}

export default ch
