import {ILocale} from "./type"

const en: ILocale = {
	header: {
		blog: "Blog",
		enterWokOS: "Enter WOLF-OS",
		enterNFT: "NFT Shop",
		features: "Features",
		franshise: "Franchise",
		vision: "Vision",
		nft: "NFT",
		staking: "Staking",
		roadmap: "Roadmap",
		token: "Token",
		bookOfKnowlage: "Books & Music",
	},
	playSection: {
		openWorld: "open world metaverse gamefi",
		sound: "Sound: ",
		on: "on",
		off: "off"
	},
	subscribeSection: {
		dontMiss: "Don\'t miss the boat",
		weAreGoing: "Reserve your seat",
		beTheFirst: "Sign up to earn FREE NFTs, rewards, early access & exclusive updates",
		subscribe: "Subscribe",
		success: "Your data has been submitted! See you on the Island!",
		networkError: "Network error",
		emailError: "Incorrect email",
		placeholder: "Email address",
		joinToFreeNft: "Subscribe today and",
		groupName: "join our discord",
		claimYourNft: "to claim your",
		freeNft: "FREE",
		claimNft: "NFT Wolf reward. Escape reality, join the tribe",
		title:"AI Gaming",
		definition1: "Gaming AI is a revolutionary way to use AI to create non-linear gameplay. This new approach will enable metaverses and persistent virtual worlds to entertain their participants without limitations.", definition2: "While normal games, even open-world RPGs, are linear and require players to complete certain tasks in a specific order to reach the end, the Gaming AI approach senses the player\'s abilities and adapts the difficulty, events, and gaming experience to their social status, rank, weapons, and abilities. ",
		definition3: "This creates a never-ending game that can be played in any order with total freedom of movement. This new approach to gaming will allow metaverses to offer high-quality gamification in a persistent world."
	},
	aigamingSection: {
		title:"AI Gaming",
		definition1: "Gaming AI is a revolutionary way to use AI to create non-linear gameplay. This new approach will enable metaverses and persistent virtual worlds to entertain their participants without limitations.", definition2: "While normal games, even open-world RPGs, are linear and require players to complete certain tasks in a specific order to reach the end, the Gaming AI approach senses the player\'s abilities and adapts the difficulty, events, and gaming experience to their social status, rank, weapons, and abilities. ",
		definition3: "This creates a never-ending game that can be played in any order with total freedom of movement. This new approach to gaming will allow metaverses to offer high-quality gamification in a persistent world."
	},
  sliderSection: {
    firstSlideTitle: "PROTECT THE ISLAND,\nAND YOUR FRIENDS\nFrom the Mutants",
    firstSlideText: "Go check if everyone is alright, if the electric is still on and the protections against \nthe evil guys are still working",
    firstStory: "Mutants",
    secondStory: "Open world",
    thirdStory: "Play to earn",
  },
	mapSection: {
		title: "Many locations to explore",
		firstAdvantageTitle: "8 000 000 m²",
		firstAdvantageText: "Square of an Island",
		secondAdvantageTitle: "1 000 people",
		secondAdvantageText: "Can play on the Island on the same time",
		thirdAdvantageTitle: "> 100 000",
		thirdAdvantageText: "Trees, rocks, people and many places to visit",
	},
	charactersSection: {
		title: "Customise your character with NFT",
		text: "Metaisland is a social networking platform, with a virtual avatar, chats, friendship, status and likes. You can choose your character from +150 unique 3d NFT.",
	},
	learnTheStorySection: {
		title: "Learn the story",
		firstStory: "The History",
		secondStory: "Rent a shop",
		thirdStory: "Fight Mutants",
		fourthStory: "Explore the Ocean",
	},
	nftSection: {
		title: "Unique 2D NFT",
		button: "Go to NFT shop",
		aquamen: "Aquamen",
		military: "Military",
		punks: "Punks",
		redcards: "Redcards",
		tribes: "Tribes",
		builders: "Builders",
		wolfWood: "Wolf Wood",
		wolfIron: "Wolf Iron",
		daemons: "Daemons"
	},
	playToEarnSection: {
		title: "Play 2 earn",
		subtitle: "Metaisland is a social networking platform, with a virtual avatar, chats, friendship, status and likes. You can choose your character’s skin, and build your own personality with RPG characteristics.",
		earn: "Earn",
		earnFirstTitle: "Find crypto",
		earnFirstText: "Airdrops and mining stones",
		earnSecondTitle: "win quests",
		earnSecondText: "Get quests from NPC",
		earnThirdTitle: "Rent a business",
		earnThirdText: "Restaurants or currency exchanges are for rent",
		spend: "Spend",
		spendFirstTitle: "NFT",
		spendFirstText: "Audio, Art, Skins, Objects and Abilities",
		spendSecondTitle: "Buy food",
		spendSecondText: "You need to manage your resources",
		spendThirdTitle: "Drink alcohol",
		spendThirdText: "Beer is also helps you to charge your energy",
	},
	soonSection: {
		title: "Coming soon",
		subtitle: "Original Metaverse Franchise",
		soonFirstTitle: "Advanced Game Engine",
		soonFirstText: "Meta Island processes real-time 3D engagement in an intriguing co-op open world,  made with Unreal Engine 5",
		soonSecondTitle: "Maximum Composability",
		soonSecondText: "Earn DeFi, play games, chat with others, participate to DAOs and experience a Web3 economy with Holochain for the Genesis Meta Island and its users.",
		soonThirdTitle: "Genesis Island & Franchising",
		soonThirdText: "Expand your Web3 horizon with the top notch Fintech technology. Use the ready-made opportunity of the Genesis Island or by franchising your own version of it!",
	},
	roadmapSection: {
		title: "Roadmap",
		first: "NFT sales on our market place",
		second: "First playable demo with the first island",
		third: "Dialog System, Adventure, Storytelling",
		fourth: "Combat system. Vertical development",
		fifth: "Franchising and horizontal development, business development",
		button: "Read more about Roadmap",
	},
	blogSection: {
		title: "Blog",
		button: "Read more posts",
	},
	teamSection: {
		title: "Metaisland Inc Studio",
		subtitle: "Metaisland Inc studio  is +20 talented people spread around the globe",
		nicolas: {name: "Nicolas Choukroun", profession: "CEO", about: "Ubisoft, Mindscape, Fox, Loriciel, Cryo, EA, Euronext"},
		laurent: {name: "Laurent Cluzel", profession: "Graphics Director", about: "Ubisoft, Sony, Psygnosis, Wizama, EA, Namco"},
		ethan: {name: "Ethan Lewis Maltby", profession: "Director Sound and Music", about: "UEFA, FA, NFL, RBS, Microsoft, Nestle, Cineworld, Telenet, BP, Wella, Walls, Leeds Castle, KCC"},
		iliah: {name: "Ilya Cerberex", profession: "Lead Blockchain Specialist", about: "Python, Php, C++, Full Stack, NEO Chain, Solidity, Hosting"},
		pascale: {name: "Pascale Piel", profession: "CFO", about: "Antabio SAS, Theravectys, Sofrepost, Novexel, Axa"},
		pierre: {name: "Pierre Esteve", profession: "Storywriter Musician", about: "Arte, Movies, TV, Starsync"},
		vitalik: {name: "Vitalik Mraksoll", profession: "Blockchain Developer", about: "C++, React, Python, Solidity, Rust"},
		sanny: {name: "Sanni Muhammed", profession: "Head of Community Manager", about: "Twitter, Reddit, Discord"},
		daniel: {name: "Daniel Morar", profession: "Lead Unreal developer", about: "Blueprint, ALS Character specialist"},
		kriss: {name: "Krzysztof Sandomierski", profession: "Project Manager & Tester", about: "20+ years experience managing teams in the petroleum industry."},		
		abhay: {name: "Abhay Prakashchandra Mahajan", profession: "Backend / WOK-OS Api", about: "PHP/Mysql wizard"},		
		stephane: {name: "Stephane Picq", profession: "Game Music Composer", about: "Atlantis, Dune, Megarace, Captain Blood"},
		beesirin: {name: "Beesirin Impaw", profession: "General Manager Thailand", about: ""},
		sandomierski: {name: "Sandomierski Krzysztof", profession: "Project Manager", about: ""},
	},
		advisorySection: {
		title: "Advisory",
		hassan: {name: "Hassan (Hatu) Sheikh", profession: "Token Economy, Fundraising", about: "Daomaker Co-founder. Founder of ApeTerminal.io"},
		iftikhar: {name: "Iftikhar Qasim", profession: "CEO SL2 Capital", about: "SL2 Capital & Hillstone Finance"},
		serge: {name: "Serge Hascoet", profession: "Chief Creative Officer at Ubisoft for +30 years", about: ""},
		david: {name: "David Atkinson", profession: "Token Economy Advisor", about: "Holochain co-founder"},
		andrey: {name: "Andrey He", profession: "Marketing & Fundraising", about: "NEO chain"},
		babar: {name: "Babar Shabir", profession: "NFT and Blockchain Stratergy", about: ""},
		konstantin: {name: "Konstantin Pechesky", profession: "President of Trinity, investment & crypto expert", about: "Trinity - Venture Capital Club"},		
	},
	partnersSection: {
		title: "Partners",
		subtitle: "The Team has published games or worked for these following companies",
		investors: "Seed Investors",
		technologyAndLegalPartners: "Technology and Legal partners",
		wizama: "Magical board game builders since 2017",
		nintendo: "Japanese multinational video game company",
		sega: "Japanese multinational video game and entertainment company",
		ea: "American video game company",
		sony: "Japanese multinational conglomerate corporation",
		mindscape: "French video game developer",
		cryo: "French video game development",
		ubisoft: "French video game company",
	},
	popUpMining: {
		title: "Mining and Airdrop area",
		text: "Every 2 hours, an alarm sounds, and an airdrop is launched. All the crypto not cached by players after 4 minutes is automatically destroyed.",
		more: "More"
	},
	popUpFactory: {
		title: "Electric Factory",
		text: "In the game, the whole electric system is fully simulated. Cables are deserving all the areas and there are even some solar panels. The Tesla Coils are sending the energy to the Electric factory where it is then managed, stored and delivered to the rest of the Island. There are also ways to turn on and off the electricity in this area.",
		more: "More",
	},
	popUpCyber: {
		title: "Cyber area",
		text: "The Cyber area is one of the biggest building of the Island. About 500 people can find a room there. It is a wild place with alcohol, drugs and prostitutes.",
		more: "More",
	},
	popUpSlum: {
		title: "Slum Ville",
		text: "This is the cheapest place to get a room when you are starting the game. It is far from the mining/airdrop area, but has a cool vibe. Good for starting the game.",
		more: "More",
	},
	popUpTech: {
		title: "Tech Ville",
		text: "Tech Ville is the administrative area. Featuring meeting rooms and the teleporter station. Also there are knowledgeable people in the area, where to get information about the Island. The area feature a museum, some houses, the teleporter station, some restaurants, a food plaza and a supermarket.",
		more: "More",
	},
	popUpLeaw: {
		title: "Leaw Beach",
		text: "Leaw Beach is the first beach to visit. We will update the game to have many more. It is the place to relax, and enjoy a nightclub or a bar.",
		more: "More",
	},
	popUpTesla: {
		title: "Tesla Coil area",
		text: "These area are generating the Energy for all the structures in the Island. The energy comes from the crypto mines under the ground. There are several Tesla area spread on the Island, some are in the Wild Zone and dangerous because they are self protecting themselves by destroying any humans coming close. The Tesla Energy area is located close to Techville, and the players have to insure that it is always working. There are ways to turn it on and off, and some players will have to get the knowledge to avoid any power cut to the infrastructure that might put the protected zone at risk to be invaded by the Wild Zone creatures.",
		more: "More",
	},
	popUpTribe: {
		title: "Tribe Village",
		text: "This is the cheapest place to get a room when you are starting the game. It is far from the mining/airdrop area, but has a cool vibe. Good for starting the game.",
		more: "More",
	},
	footer: {
		whitepaper: "Whitepaper",
		pitchdeck: "Pitch Deck Detailed",
		pitchdecklite: "Pitch Deck Lite",
		metaisland: "METAISLAND",
	},
	islToken: {
		title: "ISL token",
		subtitle: "The ISL token is the Primary ETH token of Metaisland ecosystem: the layer-1, Metaverse,\nNFT ecosystem, and native GameFi. The token\neconomy includes staking, locks, liquidity\nincentives, buy backs, and burns. These are driven\nby demand drawn by both gameplay perks and\nvalue drawn from the metaverse economy.",
		tokenEconomy: "Token Economy",
		playIncome: "Play Income",
		playIncomeFirstTitle: "Monthly Subscription",
		playIncomeFirstText: "The Metaverse is free to play but to unlock all the possibilities, the participants must pay a monthly fee of $10 minimum.",
		playIncomeSecondTitle: "Merchandizing",
		playIncomeSecondText: "Metaisland is a franchise with characters and stories, which allow us to create all kind of merchandizing like in any popular franchises.",
		playIncomeThirdTitle: "Staked Play",
		playIncomeThirdText: "Players can rent a shop in order to have a MPR (Monthly Percentage Revenu). The Staking % depends on the shop location and success.",
		nftIncome: "NFT Income",
		nftIncomeFirstTitle: "NFT Marketplace",
		nftIncomeFirstText: "A 2.5% fee applies on all NFT trades on the in-app NFT marketplace",
		nftIncomeSecondTitle: "NFT Sales",
		nftIncomeSecondText: "Our NFTs are unlocking features in the Metaverse, players will have to collect many of them to access to all these features.",
		nftIncomeThirdTitle: "NFT Rentals",
		nftIncomeThirdText: "Player can rent our NFTs with their Play to Earn money, and also rent the NFTs they own, to make extra money.",
	},
	tokenEconomy: {
		firstTitle: "ISL Token Value from Game Economy",
		gameEconomyFirstTitle: "Buy-backs",
		gameEconomyFirstText: "50% of play income is used to buy tokens on the market",
		gameEconomySecondTitle: "Burns",
		gameEconomySecondText: "Governance can vote to burn up to 50% of bought back tokens",
		gameEconomyThirdTitle: "Staking Rewards",
		gameEconomyThirdText: "Up to 50% of market bought tokens are distributed to stakers",
		secondTitle: "ISL Token Value\n in ecosystem",
		ecosystemFistTitle: "Staking",
		ecosystemFistText: "Staking tokens and LP tokens unlocks certain WOK and Avatar skins. Avatars are visible on the leaderboard, to friends, and on the public map",
		ecosystemSecondTitle: "Discounts",
		ecosystemSecondText: "2.5% royalty fee is charged on the trades of game NFTs, i.e. skins. This fee is discounted to 2% if the player is staking a minimum amount of game tokens",
		ecosystemThirdTitle: "Buy-backs",
		ecosystemThirdText: "50% of NFT trading fees are bought back on the market, which the DAO may vote to burn",
		ecosystemFourthTitle: "Staking Yield",
		ecosystemFourthText: "Up to 50% of NFT trading fees are available as staking rewards",
	},
	tokenDistribution: {
		title: "Token Distribution",
		secondTitle: "Total: 100% — 1,000,000,000",
	},
	visionMetaisland: {
		title: "What is Metaisland?",
		subtitle: "A metaverse infrastructure for billions of users",
		text: "Metaisland is a blockchain infrastructure custom — built for the Metaverse. Compatible with most popular wallets, bridged with all major blockchains, and that combines defi, NFTs and play to earn in an unique revolutionary concept.",
	},
	metaverseBlock: {
		firstTitle: "Metaverse economy worth $13 trillion by 2030",
		secondTitle: "A Metaverse for everyone",
		subtitle: "Wall Street firm Morgan Stanley sees the metaverse as an $13 trillion addressable market which is likely to become the “next generation social media, streaming and gaming platform.”\n" +
			"The City is even more optimistic and said: “We believe the Metaverse is the next generation of the internet — combining the physical and digital world in a persistent and immersive manner — and not purely a Virtual Reality world,” the report reads. “A device-agnostic Metaverse accessible via PCs, game consoles, and smartphones could result in a very large ecosystem.”",
		date: "(Fortune April 2022)",
		FirstTitle: "Metaisland ISL Utility token",
		FirstText: "The ISL Token is the fuel that empowers Metaisland. Island owners need to buy the ISL token on the market to refill their reward tank. ",
		SecondTitle: "2D NFT Multichain",
		SecondText: "9 collections of 2d PFP NFTs are available, all having an utility. They will be avaible on multi-chain and many different marketplaces",
		ThirdTitle: "3d NFT with RPG features",
		ThirdText: "Our 3d NFT are directly playable in game and have Role Playing Game features like force, speed, life, mana, hunger, thirst etc...",
		FourthTitle: "NFT shop, to buy or rent",
		FourthText: "Our NFTs can be purchased with cash, crypto, or even rented! No need to be rich to enjoy Metaisland.",
		FifthTitle: "WOK-OS to manage your ID",
		FifthText: "The WOK-OS is our backend to manage users Identity, it guarantee the privacy of every Metaverse participant. ",
		SixthTitle: "Metaisland Franchise",
		SixthText: "The best Metaverse users are awarded the access to manage their own Island and make a living by running a Metaisland of their own.",
		firstAdvantageTitle: "Subscriptions with cash",
		firstAdvantageText: "One Island with 1000 players is generating 20, 000.00 USD per month in sales",
		secondAdvantageTitle: "Subscriptions with crypto",
		secondAdvantageText: "1 million players would generate millions USDT in sales per month",
		thirdAdvantageTitle: "NFT sales",
		thirdAdvantageText: "While NFT are not mandatory to play, their unique attributes are making them very attractive",
		fourthAdvantageTitle: "NFT royalties",
		fourthAdvantageText: "10% for each resale can  generate a huge revenue stream over time",
		fifthAdvantageTitle: "Franchising",
		fifthAdvantageText: "Franchising allows us to become content provider and selling API keys without having to localize in every country",
		sixthAdvantageTitle: "Merchandising",
		sixthAdvantageText: "T-shorts, mugs, memorabilia and other game related items for sale in the online shop",
	},
	visionSlider: {
		title: "Metaisland Delivers a Metaverse for everyone",
		subtitle: "Metaisland is a social networking platform, with a virtual avatar, chats, friendship, status and likes. You can choose a character from  as many as 150+ unique 3D Characters and build your own personality by using RPG like characteristics.",
	},
	booksPlay: {
		title: "Metaisland",
		text: "HAS UNIQUE series of Book & Music",
	},
	books: {
		titleMusic: "Choose your Music",
		firstTitleMusic: "Metaisland Album 1",
		firstDescriptionMusic: "Orchestral/Cinematic Music",
		firstTextMusic: "By Ethan Lewis Maltby",		
		secondTitleMusic: "Metaisland Album 2",
		secondDescriptionMusic: "Ambient/Melodic/Techno Music",
		secondTextMusic: "By Stephane Picq",						
		titleBooks: "Choose your book",
		
		title: "Choose your book",
		firstTitle: "TheRed Cards",
		firstDescription: "This book sets the stage for an epic tale of survival, delving into the profound impact of the asteroid's crystals on both humanity and the environment.",
		firstText: "By Nicolas Choukroun",
		secondTitle: "The Tribes",
		secondDescription: "The aftermath of the asteroid's impact continues to ripple across the globe. As the crystals infect all forms of life, groups of survivors form alliances and tribes.",
		secondText: "By Nicolas Choukroun",
		thirdTitle: "The Punks",
		thirdDescription: "The crystal mutations give rise to a new faction—the Punks. Known for their rebellious and chaotic nature, they are deeply influenced by the crystals.",
		thirdText: "By Nicolas Choukroun",
		fourthTitle: "Military",
		fourthDescription: "Description",
		fourthText: "By Nicolas Choukroun, Nick Brown",
		fifthTitle: "Punks",
		fifthDescription: "Description",
		fifthText: "By Nicolas Choukroun, Nick Brown",
		sixthTitle: "Daemons",
		sixthDescription: "Description",
		sixthText: "By Nicolas Choukroun, Nick Brown",
		seventhTitle: "Builders",
		seventhDescription: "Description",
		seventhText: "By Nicolas Choukroun, Nick Brown",
		eightsTitle: "Wolf wood",
		eighthDescription: "Description",
		eightsText: "By Nicolas Choukroun, Nick Brown",
		ninthTitle: "Wolf iron",
		ninthDescription: "Description",
		ninthText: "By Nicolas Choukroun, Nick Brown",
	},
	stakingSlider: {
		title: "Staking is actually renting a shop",
		subtitle: "You can own or rent our 3D NFTs. Each series has its specific characteristics inspired by the Role Playing Games (RPG). They are playable within the game",
		secondTitle: "Click to rent your own shop",
		button: "Go to rental",
	},
	stakingMechanism: {
		title: "Metaisland",
		subtitle: "HAS UNIQUE STAKING MECHANISM",
		button: "Go to Staking",
		firstStep: "RENT A SHOP IN METAISLAND",
		secondStep: "EACH SHOP HAS ITS MPR (Montly Percentage Rate)",
		thirdStep: "EVERY MONTH GET PAID % MPR OF THE RENTAL PRICE",
	},
	nftMechanism: {
		title: "Metaisland",
		subtitle: "HAS UNIQUE NFT features",
		button: "Go to NFT SHOP",
		firstStep: "Go to NFT Shop",
		secondStep: "You can own or rent NFTs, pay with cash, wallet",
		thirdStep: "Now you can use your NFT inside the Metaverse",
	},
	pageNotFound: {
		title: "404 - Page Not Found",
		subtitle: "Do you miss something?",
		button: "Go to the Main Page",
	},
	sinceTimer: {
		title: "We are building Metaisland for",
		subtitle: "Follow us and join our community!",
		second: "second",
		minute: "minute",
		hour: "hour",
		day: "day",
		month: "month",
		year: "year",
		seconds: "seconds",
		minutes: "minutes",
		hours: "hours",
		days: "days",
		months: "months",
		years: "years",
	},
	subscribeEmail: {
		title: "Survivor, you did it!",
		alreadySub: "Already joined!",
		subtitle: "Welcome to Metaisland! \nYou have taken the first step in escaping the brutal reality and boredom of reality!\n At Metaisland, we are creating a future virtual reality, with an economy a social system and plenty of ways to entertain yourself in a toxic free environment. A decentralized place where freedom of speech is respected and where you can enjoy meeting friends and have create a different personality, do things extraordinary and live in an intriguing Island full of mystery. \nYou can enjoy a beer and relax, talk with friends, or go after the Mutants in the Wild Zone and have a lot of action, fights and explosions. You can also monetize the time spent in the game with all kind of DEFI possibilities that no other entertainment product is proposing. \nMetaverses are the FUTURE! And no need to wait long time to participate, you can join today this revolution. \nAs a token of appreciation for joining us on our journey of building Metaisland, we are delighted to offer you an opportunity to win a free NFT \nThis NFT is a FOUNDER NFT and will have the unique possibility to enter any Metaisland without asking for citizenship, as a VIP member of the project. Don\’t miss this opportunity!",
		toRedeem: "To redeem this Free NFT, follow these steps.",
		discord: "Discord",
		joinDiscord: "Join our Discord server:",
		crew: "Crew3",
		joinCrew: "Go to our Crew 3 page and complete the tasks:",
		bottomText: "Check out our weekly podcast series, Meta Talks, for all the latest info on Metaverse. Nikko Founder and CEO from Metaisland has got you covered, so be sure to join in and learn more about Crypto, GameFi, and the Metaverse itself. \nLet\’s build Metalsland together!",
		talkWith: "Meta Talk With Nikko CEO MetaIsland - WIN FREE NFT - YouTube",
		button: "Go to the Main Page",
	},
	box: {
		title: "Click to generate NFT MAGIC BOX!",
		button: "Generate",
		nftTitle: "Exclusive offer for the early adopters",
		nftSubtitle: "You will receive 3 NFTs for the price of one! For the price of 0.1 ETH only",
		firstNftTitle: "Daemons#41",
		firstNftText: "NFT 2D Avatars",
		secondNftTitle: "Charlie#08",
		secondNftText: "NFT 2D Avatars",
		thirdNftTitle: "Wolf wood#175",
		thirdNftText: "NFT 2D Avatars",
	},
	browseNft: {
		title: "METAISLAND 3D NFT",
		subtitle: "You can own or rent 3D NFTs. Each series has its specific characteristics inspired by Role Playing Games (RPG). They are playable within the game and are looking exactly like this",
		secondTitle: "Click to BROWSE NFT",
		button: "Browse",
		firstNftTitle: "John#04",
		firstNftText: "NFT 3D Avatars",
		secondNftTitle: "Ariana#05",
		secondNftText: "NFT 3D Avatars",
		thirdNftTitle: "Assassin#01",
		thirdNftText: "NFT 3D Avatars",
	},
	mysterySlider: {
		title: "METAISLAND 2D NFT",
		subtitle: "Our Profile 2D NFT all have specific features, like giving the ability to swim or to be imune to poison. We have 7 Factions, each of them with an history and with in-game utility. Players can have an NFT portfolio that They can use in the Metaverse to add more capacities to their 3d character",
		secondTitle: "Click for more info about factions",
		button: "Book of Knowledge",
		secondButton: "Browse NFT WOLF PASSES",
		wolfTitle: "METAISLAND WOLF PASS",
		wolfText: "Our Wolf Passes are used to give access to secret hidden place in the Island. They are also use as voting right for our D.A.O and access to private Betas. Finally they are cool and creative, and are great for using as your profile picture in your favorite social network. All our NFTs have 2 version, a clean one with no text, and a text enhanced one.",
		nftTitle: "WOLF PASS",
		nftText: "Metaisland",
	},
}

export default en
