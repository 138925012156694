import {ILocale} from "./type"

const hi: ILocale = {
	header: {
		blog: "Blog",
		enterWokOS: "WOLF-OS में प्रवेश करें",
		enterNFT: "NFT Shop",
		features: "विशेषताएँ",
		franshise: "मताधिकार",
		vision: "दृष्टिकोण",
		nft: "NFT",
		staking: "Staking",
		roadmap: "Roadmap",
		token: "Token",
		bookOfKnowlage: "किताबें और संगीत",
	},
	playSection: {
		openWorld: "ai gamaing ओपन वर्ल्ड metaverse गेमफी",
		sound: "आवाज़: ",
		on: "शुरू करो",
		off: "बंद करो"
	},
	"subscribeSection": {
		"dontMiss": "नौका को न चूकें",
		"weAreGoing": "हम जल्द ही बाजार जा रहे हैं",
		"beTheFirst": "जानने वाले पहले व्यक्ति बनें और अवसर को न चूकें",
		"subscribe": "सदस्यता लें",
		"success": "आपका डेटा सबमिट कर दिया गया है! द्वीप पर मिलते हैं!",
		"networkError": "नेटवर्क त्रुटि",
		"emailError": "गलत ईमेल",
		"placeholder": "ई-मेल एड्रेस",
		"joinToFreeNft": "आज ही सदस्यता लें और",
		"groupName": "हमारे डिस्कॉर्ड से जुड़ें",
		"claimYourNft": "अपने",
		"freeNft": "FREE",
		"claimNft": "NFT वुल्फ इनाम प्राप्त करें। वास्तविकता से बचें, जनजाति में शामिल हों",
		"title": "AI गेमिंग",
		"definition1": "गेमिंग AI एक क्रांतिकारी तरीका है, जो AI का उपयोग करके गैर-रेखीय खेल निर्माण करता है। यह नया दृष्टिकोण मेटावर्स और स्थिर वर्चुअल दुनिया को उनके प्रतिभागियों को बिना किसी सीमा के मनोरंजन करने में सक्षम करेगा।",
		"definition2": "जबकि सामान्य खेल, यहां तक कि ओपन-वर्ल्ड RPGs, रेखीय होते हैं और खिलाड़ियों को अंत तक पहुंचने के लिए विशिष्ट आदेश में कुछ कार्यों को पूरा करना आवश्यक होता है, गेमिंग AI दृष्टिकोण खिलाड़ी की क्षमताओं को समझता है और उनकी सामाजिक स्थिति, रैंक, हथियारों और क्षमताओं के अनुसार कठिनाई, घटनाओं और खेल अनुभव को अनुकूलित करता है।",
		"definition3": "यह एक कभी न खत्म होने वाला खेल बनाता है जिसे किसी भी आदेश में और पूरी गति से खेला जा सकता है। यह नया खेल दृष्टिकोण मेटावर्स को स्थिर दुनिया में उच्च गुणवत्ता वाली गेमिफिकेशन प्रदान करने में सक्षम बनाएगा।"
	},
	"aigamingSection": {
		"title": "AI गेमिंग",
		"definition1": "गेमिंग AI एक क्रांतिकारी तरीका है, जो AI का उपयोग करके गैर-रेखीय खेल निर्माण करता है। यह नया दृष्टिकोण मेटावर्स और स्थिर वर्चुअल दुनिया को उनके प्रतिभागियों को बिना किसी सीमा के मनोरंजन करने में सक्षम करेगा।",
		"definition2": "जबकि सामान्य खेल, यहां तक कि ओपन-वर्ल्ड RPGs, रेखीय होते हैं और खिलाड़ियों को अंत तक पहुंचने के लिए विशिष्ट आदेश में कुछ कार्यों को पूरा करना आवश्यक होता है, गेमिंग AI दृष्टिकोण खिलाड़ी की क्षमताओं को समझता है और उनकी सामाजिक स्थिति, रैंक, हथियारों और क्षमताओं के अनुसार कठिनाई, घटनाओं और खेल अनुभव को अनुकूलित करता है।",
		"definition3": "यह एक कभी न खत्म होने वाला खेल बनाता है जिसे किसी भी आदेश में और पूरी गति से खेला जा सकता है। यह नया खेल दृष्टिकोण मेटावर्स को स्थिर दुनिया में उच्च गुणवत्ता वाली गेमिफिकेशन प्रदान करने में सक्षम बनाएगा।"
	},	
	sliderSection: {
		firstSlideTitle: "Mutants से द्वीप, और अपने दोस्तों की रक्षा करें",
		firstSlideText: "जाकर जांचें कि क्या सब ठीक हैं, और बिजली अभी भी चालू है और दुष्ट लोगों से सुरक्षा अभी भी काम कर रही है",
		firstStory: "Mutants",
		secondStory: "खुली दुनिया",
		thirdStory: "कमाने के लिए खेलें"
	},
	mapSection: {
		title: "तलाशने के लिए कई स्थान",
		firstAdvantageTitle: "8 000 000 वर्ग मीटर",
		firstAdvantageText: "एक द्वीप का चौकोर",
		secondAdvantageTitle: "1 000 प्रतिभागी",
		secondAdvantageText: "एक ही समय में द्वीप पर खेल सकते हैं",
		thirdAdvantageTitle: "> 100 000",
		thirdAdvantageText: "पेड़, चट्टानें, लोग और कई स्थानों पर जाने के लिए",
	},
	charactersSection: {
		title: "NFT के साथ अपने चरित्र को अनुकूलित करें",
		text: "Metaisland एक सोशल नेटवर्किंग प्लेटफॉर्म है, जिसमें एक आभासी अवतार, चैट, दोस्ती, स्थिति और पसंद है। आप अपने चरित्र को +150 अद्वितीय 3d NFT से चुन सकते हैं।",
	},
	learnTheStorySection: {
		title: "कहानी जानें",
		firstStory: "इतिहास",
		secondStory: "दुकान किराए पर लो",
		thirdStory: "Mutants से लड़ें",
		fourthStory: "समुद्र का अन्वेषण करें",
	},
	nftSection: {
		title: "अद्वितीय 2D NFT",
		button: "NFT shop पर जाएं",
		aquamen: "Aquamen",
		military: "Military",
		punks: "Punks",
		redcards: "Redcards",
		tribes: "Tribes",
		builders: "Builders",
		wolfWood: "Wolf wood",
		wolfIron: "Wolf iron",
		daemons: "Daemons"
	},
	playToEarnSection: {
		title: "Play 2 earn",
		subtitle: "Metaisland एक सोशल नेटवर्किंग प्लेटफॉर्म है, जिसमें एक आभासी अवतार, चैट, दोस्ती, स्थिति और पसंद है। आप अपने चरित्र की त्वचा का चयन कर सकते हैं, और RPG विशेषताओं के साथ अपने स्वयं के व्यक्तित्व का निर्माण कर सकते हैं।",
		earn: "कमाये",
		earnFirstTitle: "Crypto खोजें",
		earnFirstText: "Airdrops और खनन पत्थर",
		earnSecondTitle: "खोज जीतें",
		earnSecondText: "NPC से खोज प्राप्त करें",
		earnThirdTitle: "व्यवसाय किराए पर लें",
		earnThirdText: "रेस्तरां या मुद्रा विनिमय किराए पर लेने के लिए हैं",
		spend: "खर्च करे",
		spendFirstTitle: "NFT",
		spendFirstText: "आवाज, कला, खाल, वस्तुएं और क्षमताएं",
		spendSecondTitle: "खाना खरीदें",
		spendSecondText: "आपको अपने संसाधनों का प्रबंधन करने की आवश्यकता है",
		spendThirdTitle: "शराब पिए",
		spendThirdText: "बीयर आपकी ऊर्जा को चार्ज करने में भी आपकी मदद करती है",
	},
	soonSection: {
		title: "जल्द आ रहा है",
		subtitle: "मूल Metaverse मताधिकार",
		soonFirstTitle: "उन्नत खेल यन्त्र",
		soonFirstText: "Meta द्वीप एक दिलचस्प सहकारी खुली दुनिया में रीयल-टाइम 3D जुड़ाव की प्रक्रिया करता है, जिसे Unreal Engine 5 के साथ बनाया गया है",
		soonSecondTitle: "अधिकतम संगति",
		soonSecondText: "DeFi कमाएं, खेल खेलें, दूसरों के साथ चैट करें, DAO में भाग लें और उत्पत्ति Meta द्वीप और उसके उपयोगकर्ताओं के लिए Holochain के साथ Web3 अर्थव्यवस्था का अनुभव करें।",
		soonThirdTitle: "उत्पत्ति द्वीप & मताधिकार",
		soonThirdText: "शीर्ष पायदान वाली फिनटेक तकनीक के साथ अपने Web3 क्षितिज का विस्तार करें। उत्पत्ति द्वीप के तैयार किए गए अवसर का उपयोग करें या इसके अपने स्वयं के संस्करण का मताधिकार बनाके उपयोग करें!",
	},
	roadmapSection: {
		title: "Roadmap",
		first: "हमारे बाजार में NFT बिक्री",
		second: "पहले द्वीप के साथ पहला खेलने योग्य डेमो",
		third: "संवाद प्रणाली, साहसिक कार्य, कहानी सुनाना",
		fourth: "युद्ध प्रणाली। कार्यक्षेत्र विकास",
		fifth: "Franchise और क्षैतिज विकास, व्यवसाय विकास",
		button: "Read more about Roadmap",
	},
	blogSection: {
		title: "Blog",
		button: "और लेख पढ़ें",
	},
	"teamSection": {
		"title": "Metaisland Inc. स्टूडियो",
		"subtitle": "Metaislnad Inc. स्टूडियो +20 प्रतिभाशाली लोगों द्वारा बनाया गया है जो दुनिया भर में फैले हुए हैं",
		"nicolas": {"name": "Nicolas Choukroun", "profession": "CEO", "about": "Ubisoft, Mindscape, Fox, Loriciel, Cryo, EA, Euronext"},
		"laurent": {"name": "Laurent Cluzel", "profession": "ग्राफिक्स निदेशक", "about": "Ubisoft, Sony, Psygnosis, Wizama, EA, Namco"},
		"ethan": {"name": "Ethan Lewis Maltby", "profession": "साउंड और म्यूजिक निदेशक", "about": "UEFA, FA, NFL, RBS, Microsoft, Nestle, Cineworld, Telenet, BP, Wella, Walls, Leeds Castle, KCC"},
		"iliah": {"name": "Ilya Cerberex", "profession": "लीड ब्लॉकचेन विशेषज्ञ", "about": "Python, Php, C++, Full Stack, NEO Chain, Solidity, Hosting"},
		"pascale": {"name": "Pascale Piel", "profession": "CFO", "about": "Antabio SAS, Theravectys, Sofrepost, Novexel, Axa"},
		"pierre": {"name": "Pierre Esteve", "profession": "स्टोरी राइटर संगीतकार", "about": "Arte, Movies, TV, Starsync"},
		"vitalik": {"name": "Vitalik Mraksoll", "profession": "ब्लॉकचेन डेवलपर", "about": "C++, React, Python, Solidity, Rust"},
		"sanny": {"name": "Sanni Muhammed", "profession": "हेड ऑफ कम्युनिटी मैनेजर", "about": "Twitter, Reddit, Discord"},
		"daniel": {"name": "Daniel Morar", "profession": "लीड Unreal डेवलपर", "about": "Blueprint, ALS Character specialist"},
		"kriss": {"name": "Krzysztof Sandomierski", "profession": "प्रोजेक्ट मैनेजर & टेस्टेर", "about": "20+ वर्षों का अनुभव पेट्रोलियम उद्योग में टीमों का प्रबंधन"},
		"abhay": {"name": "Abhay Prakashchandra Mahajan", "profession": "Backend / WOK-OS Api", "about": "PHP/Mysql विशेषज्ञ"},
		"stephane": {"name": "Stephane Picq", "profession": "गेम म्यूजिक कंपोज़र", "about": "Atlantis, Dune, Megarace, Captain Blood"},
		"beesirin": {"name": "Beesirin Impaw", "profession": "जनरल मैनेजर थाईलैंड", "about": ""},
		"sandomierski": {"name": "Sandomierski Krzysztof", "profession": "प्रोजेक्ट मैनेजर", "about": ""}
	},
	"advisorySection": {
		"title": "सलाहकार",
		"hassan": {"name": "Hassan (Hatu) Sheikh", "profession": "टोकन अर्थव्यवस्था, फंडरेज़िंग", "about": "Daomaker सह-संस्थापक, ApeTerminal.io के संस्थापक"},
		"iftikhar": {"name": "Iftikhar Qasim", "profession": "सीनियर सलाहकार SL2 Capital & Hillstone Finance", "about": ""},
		"serge": {"name": "Serge Hascoet", "profession": "Ubisoft में चीफ क्रिएटिव ऑफिसर 30+ वर्षों तक", "about": ""},
		"david": {"name": "David Atkinson", "profession": "टोकन अर्थव्यवस्था सलाहकार", "about": "Holochain सह-संस्थापक"},
		"andrey": {"name": "Andrey He", "profession": "मार्केटिंग & फंडरेज़िंग", "about": "NEO चेन"},
		"babar": {"name": "Babar Shabir", "profession": "NFT और ब्लॉकचेन रणनीति", "about": ""},
		"konstantin": {"name": "Konstantin Pechesky", "profession": "Trinity के अध्यक्ष, निवेश और क्रिप्टो विशेषज्ञ", "about": "Trinity - वेंचर कैपिटल क्लब"}
	},
	partnersSection: {
		title: "भागीदार",
		subtitle: "The Team has published games or worked for these following companies",
		investors: "बीज निवेशक",
		technologyAndLegalPartners: "Technology and Legal partners",
		wizama: "Magical board game builders since 2017",
		nintendo: "Japanese multinational video game company",
		sega: "Japanese multinational video game and entertainment company",
		ea: "American video game company",
		sony: "Japanese multinational conglomerate corporation",
		mindscape: "French video game developer",
		cryo: "French video game development",
		ubisoft: "French video game company",
	},
	popUpMining: {
		title: "Mining और Airdrop क्षेत्र",
		text: "हर 2 घंटे में एक अलार्म बजता है, और एक airdrop लॉन्च किया जाता है। 4 मिनट के बाद खिलाड़ियों द्वारा कैश नहीं की गई सभी crypto स्वचालित रूप से नष्ट हो जाती है।",
		more: "अधिक"
	},
	popUpFactory: {
		title: "Electric Factory",
		text: "खेल में पूरा विद्युत प्रणाली पूरी तरह से सिम्युलेटेड है। केबल सभी क्षेत्रों के योग्य हैं और यहां तक कि कुछ सौर फलक भी हैं। Tesla Coils ऊर्जा को विद्युत कारखाने में भेज रहे हैं जहां इसे फिर से प्रबंधित, संग्रहीत और शेष द्वीप में वितरित किया जाता है। इस क्षेत्र में बिजली चालू और बंद करने के तरीके भी हैं।",
		more: "अधिक",
	},
	popUpCyber: {
		title: "Cyber area",
		text: "Cyber क्षेत्र द्वीप की सबसे बड़ी इमारतों में से एक है। लगभग 500 लोगों को वहां एक कमरा मिल सकता है। यह शराब, नशा करने वाली सामग्री और वेश्याओं के साथ एक जंगली जगह है।",
		more: "अधिक",
	},
	popUpSlum: {
		title: "Slum Ville",
		text: "जब आप खेल शुरू कर रहे हों तो कमरा पाने के लिए यह सबसे सस्ती जगह है। यह खनन/airdrop क्षेत्र से बहुत दूर है, लेकिन इसमें शांत वातावरण है। खेल की शुरुआत के लिए अच्छा है।",
		more: "अधिक",
	},
	popUpTech: {
		title: "Tech Ville",
		text: "Tech Ville प्रशासनिक क्षेत्र है। बैठक कक्ष और टेलीपोर्टर स्थानक की विशेषता है। साथ ही क्षेत्र में जानकार लोग हैं, जहां से द्वीप के बारे में जानकारी प्राप्त की जा सकती है। इस क्षेत्र में एक संग्रहालय, कुछ घर, टेलीपोर्टर स्थानक, कुछ रेस्तरां, एक फूड प्लाजा और एक सुपरमार्केट है।",
		more: "अधिक",
	},
	popUpLeaw: {
		title: "Leaw Beach",
		text: "Leaw समुद्र तट पहला समुद्र तट है जिसका आप दर्शन कर सकते हो। हम खेल को अपडेट करेंगे जिस से और भी समुद्री तट खेल में जुड़े। यह आराम करने और नाइटक्लब या बार का आनंद लेने का स्थान है।",
		more: "अधिक",
	},
	popUpTesla: {
		title: "Tesla Coil zone",
		text: "ये क्षेत्र द्वीप में सभी संरचनाओं के लिए ऊर्जा पैदा कर रहे हैं। ऊर्जा जमीन के नीचे crypto खानों से आती है। द्वीप पर फैले कई Tesla क्षेत्र हैं, कुछ जंगली क्षेत्र में हैं और खतरनाक हैं क्योंकि वे पास आने वाले किसी भी इंसान को नष्ट कर स्वयं की रक्षा कर रहे हैं। Tesla ऊर्जा क्षेत्र Techville के करीब स्थित है, और खिलाड़ियों को यह सुनिश्चित करना होगा कि यह हमेशा काम कर रहा हो। इसे चालू और बंद करने के तरीके हैं, और कुछ खिलाड़ियों को बुनियादी ढांचे में किसी भी बिजली कटौती से बचने के लिए ज्ञान प्राप्त करना होगा जो संरक्षित क्षेत्र को जंगली क्षेत्र के जीवों द्वारा आक्रमण करने के जोखिम में डाल सकता है।",
		more: "अधिक",
	},
	popUpTribe: {
		title: "Tribe Village",
		text: "जब आप खेल शुरू कर रहे हों तो कमरा पाने के लिए यह सबसे सस्ती जगह है। यह खनन/airdrop क्षेत्र से बहुत दूर है, लेकिन इसमें शांत वातावरण है। खेल की शुरुआत के लिए अच्छा है।",
		more: "अधिक",
	},
	footer: {
	"whitepaper": "श्वेतपत्र",
	"pitchdeck": "पिच डेक विस्तृत",
	"pitchdecklite": "पिच डेक लाइट",
	"metaisland": "मेटाआइलैंड"
	},
	islToken: {
		title: "ISL token",
		subtitle: "ISL token Metaisland पारिस्थितिकी तंत्र का प्राथमिक ETH token है: परत -1, Metaverse, NFT पारिस्थितिकी तंत्र, और देशी गेमफाई। token अर्थव्यवस्था में दांव, ताले, तरलता, प्रोत्साहन, पुनर्खरीद और जलाना शामिल हैं। ये खेल भत्तों और Metaverse इकोनॉमी से प्राप्त मूल्य दोनों द्वारा खींची गई मांग से प्रेरित हैं।",
		tokenEconomy: "Token अर्थव्यवस्था",
		playIncome: "खेल आय",
		playIncomeFirstTitle: "मासिक सदस्यता",
		playIncomeFirstText: "Metaverse खेलने के लिए स्वतंत्र है लेकिन सभी संभावनाओं को अनलॉक करने के लिए, प्रतिभागियों को न्यूनतम $ 10 का मासिक शुल्क देना होगा।",
		playIncomeSecondTitle: "Merchandizing",
		playIncomeSecondText: "Metaisland पात्रों और कहानियों के साथ एक franchise है, जो हमें किसी भी लोकप्रिय franchise की तरह सभी तरह की merchandizing बनाने की अनुमति देती है।",
		playIncomeThirdTitle: "Staked Play",
		playIncomeThirdText: "MPR (मासिक प्रतिशत राजस्व) प्राप्त करने के लिए खिलाड़ी एक दुकान किराए पर ले सकते हैं। Staking % दुकान के स्थान और सफलता पर निर्भर करता है।",
		nftIncome: "NFT आय",
		nftIncomeFirstTitle: "NFT बाजार",
		nftIncomeFirstText: "ऐप के अंदर NFT बाजार पर सभी NFT खरीद फरोख्त पर 2.5% शुल्क लागू होता है।",
		nftIncomeSecondTitle: "NFT बिक्री",
		nftIncomeSecondText: "हमारे NFT का उपयोग Metaverse में सुविधाओं को अनलॉक करने के लिए किया जाता है, इन सभी सुविधाओं तक पहुंचने के लिए खिलाड़ियों को उनमें से कई को एकत्र करना होगा।",
		nftIncomeThirdTitle: "किराए से दिए जाने वाले NFT",
		nftIncomeThirdText: "खिलाड़ी पैसे कमाने के लिए अपने खेल के साथ हमारे NFT किराए पर ले सकते हैं और अतिरिक्त पैसे कमाने के लिए खुद के NFT को किराए पर भी दे सकते हैं।",
	},
	tokenEconomy: {
		firstTitle: "खेल अर्थव्यवस्था में ISL Token का महत्व",
		gameEconomyFirstTitle: "Buy-backs",
		gameEconomyFirstText: "खेल आय का 50% बाजार पर tokens खरीदने के लिए उपयोग किया जाता है।",
		gameEconomySecondTitle: "Burns",
		gameEconomySecondText: "शासन खरीदे गए tokens के 50% तक को जलाने के लिए मतदान कर सकता है।",
		gameEconomyThirdTitle: "Staking Rewards",
		gameEconomyThirdText: "बाजार में खरीदे गए tokens का 50% तक stakers को वितरित किया जाता है!",
		secondTitle: "पारिस्थितिकी तंत्र में ISL token का महत्व",
		ecosystemFistTitle: "Staking",
		ecosystemFistText: "Staking tokens and LP tokens कुछ कार्य और अवतार की खाल को अनलॉक करते हैं। अवतार लीडरबोर्ड पर, मित्रों को, और सार्वजनिक मानचित्र पर दिखाई देते हैं",
		ecosystemSecondTitle: "Discounts",
		ecosystemSecondText: "2.5% royalty शुल्क खेल NFT, यानी खाल के खरीद फरोख्त पर लिया जाता है। यदि खिलाड़ी खेल tokens की न्यूनतम राशि दांव पर लगा रहा है तो इस शुल्क में 2% की छूट दी जाती है",
		ecosystemThirdTitle: "Buy-backs",
		ecosystemThirdText: "NFT खरीद फरोख्त शुल्क का 50% बाजार में वापस खरीदा जाता है, जिसे DAO जलाने के लिए वोट कर सकता है",
		ecosystemFourthTitle: "Staking Yield",
		ecosystemFourthText: "NFT खरीद फरोख्त शुल्क का 50% तक staking rewards के रूप में उपलब्ध है",
	},
	tokenDistribution: {
		title: "Token वितरण",
		secondTitle: "कुल: 100% — 1,000,000,000",
	},
	visionMetaisland: {
		title: "Metaisland क्या है?",
		subtitle: "अरबों उपयोगकर्ताओं के लिए एक Metaverse बुनियादी ढांचा",
		text: "Metaisland एक blockchain infrastructure custom — built है - Metaverse के लिए बनाया गया है। सबसे लोकप्रिय wallets के साथ संगत, सभी प्रमुख blockchain के साथ, और यह एक अद्वितीय क्रांतिकारी अवधारणा में कमाने के लिए defi, NFT और खेल को जोड़ती है।",
	},
	metaverseBlock: {
		firstTitle: "2030 तक $13 खरब की Metaverse अर्थव्यवस्था",
		secondTitle: "सभी के लिए एक Metaverse",
		subtitle: "Wall Street व्यवसाय-प्रतिष्ठान Morgan Stanley Metaverse को $8 खरब संभाविक व्यापार के रूप में देखती है जो “अगली पीढ़ी के सोशल मीडिया, प्रक्षेपण और खेल का मंच” बनने की संभावना है।",
		date: "(Fortune April 2022)",
		FirstTitle: "Metaisland ISL Utility token",
		FirstText: "ISL Token वह ईंधन है जो Metaisland को सशक्त बनाता है। द्वीप मालिकों को अपने इनाम टैंक को फिर से भरने के लिए बाजार पर ISL Tokens खरीदने होंगे।",
		SecondTitle: "2D NFT Multichain",
		SecondText: "2D PFP NFT के 9 संग्रह उपलब्ध हैं, सभी में एक उपयोगिता है। ये multi-chain और कई अलग-अलग बाजारों पर उपलब्ध होंगे",
		ThirdTitle: "3d NFT with RPG विशेषताएँ",
		ThirdText: "हमारे 3D NFT सीधे खेल में खेलने योग्य हैं और इसमें खेल की विशेषताएं हैं जैसे बल, गति, जीवन, मन, भूख, प्यास आदि ...",
		FourthTitle: "NFT दुकान, खरीदने या किराए पर लेने के लिए",
		FourthText: "हमारे NFT को नकद, crypto, या किराए पर भी खरीदा जा सकता है! Metaisland का आनंद लेने के लिए अमीर होने की जरूरत नहीं है।",
		FifthTitle: "आपकी ID प्रबंधित करने के लिए WOK-OS",
		FifthText: "WOK-OS उपयोगकर्ताओं की पहचान को प्रबंधित करने के लिए हमारा backend है, यह प्रत्येक Metaverse प्रतिभागी की गोपनीयता की गारंटी देता है।",
		SixthTitle: "Metaisland Franchise",
		SixthText: "सर्वश्रेष्ठ Metaverse उपयोगकर्ताओं को अपने स्वयं के द्वीप का प्रबंधन करने और स्वयं का Metaisland चलाकर जीवन यापन करने की सुविधा प्रदान की जाती है।",
		firstAdvantageTitle: "नकद के साथ सदस्यता",
		firstAdvantageText: "1000 खिलाड़ियों वाला एक द्वीप बिक्री में प्रति माह 20, 000.00 USD उत्पन्न कर रहा है",
		secondAdvantageTitle: "Crypto के साथ सदस्यता",
		secondAdvantageText: "10 लाख खिलाड़ी प्रति माह बिक्री में लाखों USDT उत्पन्न करेंगे",
		thirdAdvantageTitle: "NFT बिक्री",
		thirdAdvantageText: "जबकि NFT खेलने के लिए अनिवार्य नहीं है, उनकी अनूठी विशेषताएं उन्हें बहुत आकर्षक बना रही हैं",
		fourthAdvantageTitle: "NFT royalties",
		fourthAdvantageText: "प्रत्येक पुनर्विक्रय के लिए 10% समय के साथ एक बड़ी राजस्व धारा उत्पन्न कर सकता है",
		fifthAdvantageTitle: "Franchising",
		fifthAdvantageText: "Franchising हमें सामग्री प्रदाता बनने और हर देश में स्थानीयकृत किए बिना API Key बेचने की अनुमति देता है",
		sixthAdvantageTitle: "Merchandising",
		sixthAdvantageText: "ऑनलाइन दुकान में बिक्री के लिए टी-शर्ट, मग, यादगार और खेल से संबंधित अन्य सामान",
	},
	visionSlider: {
		title: "Metaisland सभी के लिए एक Metaverse प्रदान करता है",
		subtitle: "Metaisland एक सोशल नेटवर्किंग मंच है, जिसमें आभासी अवतार, चैट, दोस्ती, दर्जा और लाइक्स हैं। आप 150+ अद्वितीय 3D वर्णों में से एक चरित्र चुन सकते हैं और RPG जैसी विशेषताओं का उपयोग करके अपने व्यक्तित्व का निर्माण कर सकते हैं।",
	},
	booksPlay: {
		title: "Metaisland",
		text: "पुस्तक की अनूठी श्रृंखला है",
	},
	books: {
		titleMusic: "Choose your Music",
		firstTitleMusic: "Metaisland Album 1",
		firstDescriptionMusic: "Orchestral/Cinematic Music",
		firstTextMusic: "By Ethan Lewis Maltby",		
		secondTitleMusic: "Metaisland Album 2",
		secondDescriptionMusic: "Ambient/Melodic/Techno Music",
		secondTextMusic: "By Stephane Picq",				
		
		titleBooks: "Choose your book",
		
		title: "choose your book",
		firstTitle: "The Red Cards",
		firstDescription: "विवरण",
		firstText: "Nicolas Choukroun, ",
		secondTitle: "The Tribes",
		secondDescription: "विवरण",
		secondText: "Nicolas Choukroun",
		thirdTitle: "The Punks",
		thirdDescription: "विवरण",
		thirdText: "Nicolas Choukroun",
		fourthTitle: "Military",
		fourthDescription: "विवरण",
		fourthText: "Nicolas Choukroun, Nick Brown द्वारा",
		fifthTitle: "Punks",
		fifthDescription: "विवरण",
		fifthText: "Nicolas Choukroun, Nick Brown द्वारा",
		sixthTitle: "Daemons",
		sixthDescription: "विवरण",
		sixthText: "Nicolas Choukroun, Nick Brown द्वारा",
		seventhTitle: "Builders",
		seventhDescription: "विवरण",
		seventhText: "Nicolas Choukroun, Nick Brown द्वारा",
		eightsTitle: "Wolf wood",
		eighthDescription: "विवरण",
		eightsText: "Nicolas Choukroun, Nick Brown द्वारा",
		ninthTitle: "Wolf iron",
		ninthDescription: "विवरण",
		ninthText: "Nicolas Choukroun, Nick Brown द्वारा",
	},
	stakingSlider: {
		title: "Staking वास्तव में किराए पर देने वाली दुकान है",
		subtitle: "आप हमारे 3D NFT खरीद या किराए पर ले सकते हैं। रोल प्लेइंग खेल (RPG) से प्रेरित प्रत्येक श्रृंखला की अपनी विशिष्ट विशेषताएं हैं। वे खेल में खेलने योग्य हैं",
		secondTitle: "अपनी दुकान किराए पर देने के लिए क्लिक करें",
		button: "रेंटल पर जाएं",
	},
	stakingMechanism: {
		title: "Metaisland",
		subtitle: "अद्वितीय STAKING तंत्र है",
		button: "Staking पर जाएं",
		firstStep: "METAISLAND में दुकान किराए पर लें",
		secondStep: "प्रत्येक दुकान का अपना MPR होता है (मासिक प्रतिशत दर)",
		thirdStep: "हर महीने किराये की कीमत का % MPR भुगतान मिलता है",
	},
	nftMechanism: {
		title: "Metaisland",
		subtitle: "अद्वितीय NFT विशेषताएं हैं",
		button: "NFT दुकान पर जाएं",
		firstStep: "NFT दुकान पर जाएं",
		secondStep: "आप NFT खरीद या किराए पर ले सकते हैं, नकद, wallet से भुगतान कर सकते हैं",
		thirdStep: "अब आप Metaverse के अंदर अपने NFT का उपयोग कर सकते हैं",
	},
	pageNotFound: {
		title: "404 - पृष्ठ नहीं मिला",
		subtitle: "क्या आप कुछ खो गए?",
		button: "मुख्य पृष्ठ पर जाएँ",
	},
	sinceTimer: {
		title: "We are building Metaisland for",
				subtitle: "Follow us and join our community!",
		second: "second",
		minute: "minute",
		hour: "hour",
		day: "day",
		month: "month",
		year: "year",
		seconds: "seconds",
		minutes: "minutes",
		hours: "hours",
		days: "days",
		months: "months",
		years: "years",
	},
	subscribeEmail: {
		title: "Survivor, you did it!",
		alreadySub: "Already joined!",
		subtitle: "Welcome to Metaisland! \nYou have taken the first step in escaping the brutal reality and boredom of reality!\n At Metaisland, we are creating a future virtual reality, with an economy a social system and plenty of ways to entertain yourself in a toxic free environment. A decentralized place where freedom of speech is respected and where you can enjoy meeting friends and have create a different personality, do things extraordinary and live in an intriguing Island full of mystery. \nYou can enjoy a beer and relax, talk with friends, or go after the Mutants in the Wild Zone and have a lot of action, fights and explosions. You can also monetize the time spent in the game with all kind of DEFI possibilities that no other entertainment product is proposing. \nMetaverses are the FUTURE! And no need to wait long time to participate, you can join today this revolution. \nAs a token of appreciation for joining us on our journey of building Metaisland, we are delighted to offer you an opportunity to win a free NFT \nThis NFT is a FOUNDER NFT and will have the unique possibility to enter any Metaisland without asking for citizenship, as a VIP member of the project. Don\’t miss this opportunity!",
		toRedeem: "To redeem this Free NFT, follow these steps.",
		discord: "Discord",
		joinDiscord: "Join our Discord server:",
		crew: "Crew3",
		joinCrew: "Go to our Crew 3 page and complete the tasks:",
		bottomText: "Check out our weekly podcast series, Meta Talks, for all the latest info on Metaverse. Nikko Founder and CEO from Metaisland has got you covered, so be sure to join in and learn more about Crypto, GameFi, and the Metaverse itself. \nLet\’s build Metalsland together!",
		talkWith: "Meta Talk With Nikko CEO MetaIsland - WIN FREE NFT - YouTube",
		button: "Go to the Main Page",
	},
	box: {
		title: "NFT MAGIC BOX बनाने करने के लिए क्लिक करें!",
		button: "उत्पन्न करें",
		nftTitle: "जल्दी अपनाने वालों के लिए विशेष पेशकश",
		nftSubtitle: "आपको एक की कीमत पर 3 NFT प्राप्त होंगे! केवल 0.1 ETH की कीमत के लिए",
		firstNftTitle: "Daemons#41",
		firstNftText: "NFT 2D Avatars",
		secondNftTitle: "Charlie#08",
		secondNftText: "NFT 2D Avatars",
		thirdNftTitle: "Wolf wood#175",
		thirdNftText: "NFT 2D Avatars",
	},
	browseNft: {
		title: "METAISLAND 3D NFT",
		subtitle: "आप 3D NFT खरीद या किराए पर ले सकते हैं। रोल प्लेइंग खेल (RPG) से प्रेरित प्रत्येक श्रृंखला की अपनी विशिष्ट विशेषताएं हैं। वे खेल के भीतर खेलने योग्य हैं और बिल्कुल इस तरह दिख रहे हैं",
		secondTitle: "NFT देखने के लिए क्लिक करें",
		button: "ब्राउज़",
		firstNftTitle: "John#04",
		firstNftText: "NFT 3D Avatars",
		secondNftTitle: "Ariana#05",
		secondNftText: "NFT 3D Avatars",
		thirdNftTitle: "Assassin#01",
		thirdNftText: "NFT 3D Avatars",
	},
	mysterySlider: {
		title: "METAISLAND 2D NFT",
		subtitle: "हमारी प्रोफ़ाइल 2D NFT सभी में विशिष्ट विशेषताएं हैं, जैसे तैरने की क्षमता देना या ज़हर के प्रति प्रतिरक्षित होना। हमारे पास 7 गुट हैं, जिनमें से प्रत्येक एक इतिहास और इन-गेम उपयोगिता के साथ है। खिलाड़ियों के पास एक NFT portfolio हो सकता है जिसका उपयोग वे Metaverse में अपने 3D अवतार में अधिक क्षमता जोड़ने के लिए कर सकते हैं",
		secondTitle: "गुटों के बारे में अधिक जानकारी के लिए क्लिक करें",
		button: "Book of Knowledge",
		secondButton: "NFT WOLF PASS ब्राउज़ करें",
		wolfTitle: "METAISLAND WOLF PASS",
		wolfText: "हमारे WOLF PASS का उपयोग द्वीप में गुप्त छिपे हुए स्थान तक पहुंच प्रदान करने के लिए किया जाता है। उनका उपयोग हमारे D.A.O के लिए मतदान अधिकार और private Betas तक पहुंच के रूप में भी किया जाता है। अंत में वे शांत और रचनात्मक हैं, और आपके पसंदीदा सोशल नेटवर्क में आपकी प्रोफ़ाइल तस्वीर के रूप में उपयोग करने के लिए बहुत अच्छे हैं। हमारे सभी NFT के 2 संस्करण हैं, एक साफ-सुथरा जिसमें कोई टेक्स्ट नहीं है, और एक टेक्स्ट एन्हांस्ड है।",
		nftTitle: "WOLF PASS",
		nftText: "Metaisland",
	},
}

export default hi
